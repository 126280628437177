import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useInView } from "framer-motion";
import {
  LinkedIn,
  RightArrow,
  Instagram,
  Facebook,
  TwitterIconSvg
} from "constants/svgLogos";
import { footerData } from "data/Footer";
import "components/Footer/footer.scss";
import { imageBaseUrl } from "config/constant";

const index = () => {
  const footer = useRef();
  const footerIsView = useInView(footer, { amount: 0.6, once: true });
  return (
    <footer className="footer" ref={footer}>
      <div className="container">
        <div className="footerContent">
          <div className="footerMain">
            <div
              style={{
                scale: footerIsView ? "1" : "0.5",
                transition: "all 0.6s ease-in-out"
              }}
            >
              <img src={`${imageBaseUrl}footer_LeftImg.png`} alt="image" />
            </div>
            <div className="footerHeading">
              <h2>
                Find Your Next Star Hire with <span>Speed & Accuracy</span>
              </h2>
              <p>
                Transform the way you hire and manage your employees with our
                innovative solutions.
              </p>
              <Link
                to="/get-started"
                onClick={() => {
                  window.gtag("event", "footer_get_started", {
                    event_time: new Date()
                  });
                }}
              >
                <button className="btn commonBtn">
                  Get Started
                  <RightArrow />
                </button>
              </Link>
            </div>
            <div
              style={{
                scale: footerIsView ? "1" : "0.5",
                transition: "all 0.6s ease-in-out"
              }}
            >
              <img src={`${imageBaseUrl}footer_RightImg.png`} alt="image" />
            </div>
          </div>
          <div className="navigationContainer">
            <div className="menuGrid">
              {footerData.map((column, columnIndex) => (
                <div key={columnIndex}>
                  {column.map((section) => (
                    <div key={section.title} className="menuSection">
                      <h2 className="sectionTitle">{section.title}</h2>
                      <ul className="linkList">
                        {section.list.map(
                          (item, index) =>
                            item.name && (
                              <li key={item.path || index} className="linkItem">
                                <a href={item.path} className="link">
                                  {item.name}
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <span className="footerMail">
            <img src={`${imageBaseUrl}footer_sms.svg`} alt="Mail" />
            <a href="mailto: support@quickrecruit.com">
              support@quickrecruit.com
            </a>
          </span>
        </div>
        <div className="footerLinks">
          <div className="socialLinks">
            <Link
              to="https://www.linkedin.com/company/wequickrecruit/"
              target="_blank"
            >
              <LinkedIn />
            </Link>
            <Link
              to="https://www.instagram.com/wequickrecruit/"
              target="_blank"
            >
              <Instagram />
            </Link>
            <Link to="https://www.facebook.com/wequickrecruit" target="_blank">
              <Facebook />
            </Link>
            <Link to="https://twitter.com/wequickrecruit" target="_blank">
              <TwitterIconSvg />
            </Link>
          </div>
          <div className="footerLinksPage">
            <div>
              <p>
                <Link to="/terms-conditions" className="footerLink">
                  Terms & Conditions
                </Link>
              </p>
            </div>
            <div>
              <p>
                <Link to="/privacy-policy" className="footerLink">
                  Privacy Policy
                </Link>
              </p>
            </div>
            <div>
              <p>Copyright © 2025</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
